import {
    Flex, Box, SimpleGrid, Image,
} from "@chakra-ui/react";
import React from "react";
import { StyleSheet, css } from "aphrodite";
import { useSelector } from "react-redux";

function NextconMembers () {
    
    const isMobile = useSelector(state=> state.windowSize.isMobile);

    const ColNum = isMobile ? 2 : 4;

    return(
        <>
        <Box className={css(styles.head)}>
            <Flex className={css(styles.container)}>
                NEXTCON Lab & SCVIC CENTRE Director 
            </Flex>
            <SimpleGrid columns={1} spacing={10} textAlign="center" marginTop={5} marginBottom={5}>
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-BurakKantarci_Profile.jpg" alt="Burak Kantarci" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Prof. Burak Kantarci
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Program Director
                    </Flex>
                </Box>
            </SimpleGrid>
            <hr />
            <Flex className={css(styles.container)}>
                Lab Coordination and Administration 
            </Flex>
            <SimpleGrid
                columns={ColNum}
                spacing={6}
                px={{ base: 4, md: 10 }}
                maxWidth={{ base: "100%", md: "1000px" }}
                margin="auto"
                textAlign="center"
                marginTop={5}
                marginBottom={5}
            >
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-MuratSimsek.jpg" alt="Murat Simsek" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Dr. Murat Simsek
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Research Associate & Laboratory Coordinator
                    </Flex>
                </Box>
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-Iman.png" alt="Iman Amini" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Dr. Iman Amini
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Research Associate and DND project p.m.
                    </Flex>
                </Box>
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-Elis.jpg" alt="Iraz Eliz Deniz" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Iraz Eliz Deniz
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Project Coordinator <br/> &nbsp;
                    </Flex>
                </Box>
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-ade.png" alt="Ade Ogunlami" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Ade Ogunlami
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Create Program Coordinator
                    </Flex>
                </Box>
            </SimpleGrid>
            <hr />
            <Flex className={css(styles.container)}>
                Postdoctoral Researchers 
            </Flex>
            <SimpleGrid
                columns={ColNum}
                spacing={6}
                px={{ base: 4, md: 10 }}
                maxWidth={{ base: "100%", md: "1200px" }}
                margin="auto"
                textAlign="center"
                marginTop={5}
                marginBottom={5}
            >
                
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-PoonamLohan.png" alt="Poonam Lohan" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Dr. Poonam Lohan <br/> &nbsp; <br/> &nbsp;
                    </Flex>
                </Box>
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-thumbnail_OthmaneFriha.jpg" alt="Othmane Friha" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Dr. Othmane Friha <br/> &nbsp; <br/> &nbsp;
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}> 

                    </Flex>
                </Box>
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-MichelKulhandjian.jpg" alt="Michel Kulhandjian" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Dr. Michel Kulhandjian (Visiting Research Associate)
                    </Flex>
                </Box>
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-Ainur.png" alt="Аynur Zhumadillayeva" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Dr. Aynur Zhumadillayeva (Visiting Researcher) <br/> &nbsp;
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}>

                    </Flex>
                </Box>
            </SimpleGrid>
            <hr />
            <Flex className={css(styles.container)}>
                Ph.D Students 
            </Flex>
            <SimpleGrid columns={ColNum} spacing={8} margin="auto" textAlign="center" marginTop={8} marginBottom={5} maxWidth={{ base: "100%", md: "1200px" }} >
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-Ahmed.jpg" alt="Ahmed Omara" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Ahmed Omara
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Ph.D - Elec. and Comp. Eng <br/> &nbsp;
                    </Flex>
                </Box>
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-Bin.png" alt="Bin Xiao" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Bin Xiao
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Ph.D in Computer Science <br/> &nbsp;
                    </Flex>
                </Box>
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-Didem.jpg" alt="Didem Cicek Simsek" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Didem Cicek Simsek
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Ph.D, Digital Transformation and Innovation
                    </Flex>
                </Box>
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-Samhita.jpg" alt="Samhita Kuili" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Samhita Kuili
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Ph.D - Elec. and Comp. Eng
                    </Flex>
                </Box>
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-Arda.jpg" alt="Murat Arda Onsu" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Murat Arda Onsu
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Ph.D in Computer Science
                    </Flex>
                </Box>
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-Parissa.jpg" alt="parisa fard moshiri" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Parisa Fard Moshiri
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Ph.D - Elec. and Comp. Eng
                    </Flex>
                </Box>
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-Ghazal.png" alt="Ghazal Asemian" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Ghazal Asemian
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Ph.D - Elec. and Comp. Eng
                    </Flex>
                </Box>
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-Parinaz.jpg" alt="Parinaz Kavandi" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Parinaz Kavandi
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Ph.D - Elec. and Comp. Eng
                    </Flex>
                </Box>
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-Hanqing.png" alt="Hanqing Zhou" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Hanqing Zhou
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Ph.D - Computer Science 
                    </Flex>
                </Box>
                </SimpleGrid>

                <Flex className={css(styles.container)}>
                    Master's Students 
                </Flex>
                <SimpleGrid columns={ColNum} spacing={8} margin="auto" textAlign="center" marginTop={8} marginBottom={5} maxWidth={{ base: "100%", md: "1200px" }} >
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-Xuli.jpg" alt="Xuli Cai" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Xuli Cai
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Master in Computer Science
                    </Flex>
                </Box>
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-Lansu.jpg" alt="Lansu Dai" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Lansu Dai
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Master in Computer Science
                    </Flex>
                </Box>
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="MohammedSufiyan.jpg" alt="Mohammed Sufiyan" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Mohammed Sufiyan Ali Banaganapalle
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Master in Elec. and Comp. Eng  
                    </Flex>
                </Box>
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-Oliver.jpg" alt="Oliver Benning" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Oliver Benning
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Master in Computer Science
                    </Flex>
                </Box>
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-Mahsa.png" alt="Mahsa Paknejad" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                    Mahsa Paknejad
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Master in Elec. and Comp. Eng  
                    </Flex>
                </Box>
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-ZHANGYueteng.jpg" alt="Yueteng Zhang" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Yueteng Zhang
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Master in Elec. and Comp. Eng  
                    </Flex>
                </Box>
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-Vilkas.jpg" alt="Vikas Gogia" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Vikas Gogia
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Master in Computer Science
                    </Flex>
                </Box>
                <Box justifyContent="center" alignContent="center">
                    <Flex justifyContent="center">
                        <Image src="NEXTCON-Arild.jpg" alt="Arild Yonkeu Tchana" className={css(styles.images)} />
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.names)}>
                        Arild Yonkeu Tchana
                    </Flex>
                    <Flex flexDirection="column" className={css(styles.title)}>
                        Master in Computer Science
                    </Flex>
                </Box>

            </SimpleGrid>
        </Box>
        </>
    )
};

const styles = StyleSheet.create({
    head: {
        maxWidth: '100vw',
        marginTop: 0
    },
    parent: {
        maxWidth: '100vw',
        textAlign: 'center'
    },
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 30,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
        textAlign: 'center'
    },
    textbox: {
        margin: '2%',
        marginTop: '0%',
        textAlign: 'center',
        fontSize: 18,
        justifyContent: 'center',
        alignContent: 'center'
    },
    names: {
        fontWeight: 'bold',
        fontSize: 20
    },
    title: {
        fontSize: 18,
    },
    images: {
        width: '200px',
        height: '200px',
        fit: "fit",
        borderRadius: "100%"
    },
    mobileImage: {
        width: "70%",
        borderRadius: "80%"
    }
})

export default NextconMembers