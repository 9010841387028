import React from 'react';
import { Flex, Image, Link, Icon, Text, SimpleGrid, Box } from '@chakra-ui/react';
import { StyleSheet, css } from 'aphrodite';
import { BsLinkedin } from 'react-icons/bs';

function MobileFooter() {
  return (
    <Flex className={css(styles.parent)} align="center" justify="center">
            <SimpleGrid columns={2} spacing={2} textAlign="center" marginTop={5} marginBottom={5}>
                <Box justifyContent="left" alignContent="left">
                    <Image src="NEXTCON-BurakKantarci_Profile.jpg" display='flex' alt="Director Photo" maxWidth={"80%"} maxHeight={"80%"} objectFit="contain"/>
                </Box>
                <Box justifyContent="left" alignContent="left" fontSize={"12px"}>
                    <Flex direction="column" p={[2, 4]} flexGrow={1} alignItems="flex-end">
                        <Text fontWeight="bold">For more information:</Text>
                        <Text>Dr. Burak Kantarci,</Text>
                        <Text> Professor,</Text>
                        <Text>University of Ottawa</Text>
                        <Link href="mailto:scvic-civic@uottawa.ca" color="blue.500" isExternal>scvic-civic@uottawa.ca</Link>
                        <Link href="https://nextconlab.ca/" color="blue.500" isExternal>https://nextconlab.ca/</Link>
                        <Link href="https://www.linkedin.com/company/smart-connected-vehicles-innovation-centre/about/" color="blue.500" isExternal>
                            Follow us on LinkedIn <Icon as={BsLinkedin} w={5} h={5} color="blue.500" />
                        </Link>
                    </Flex>
                </Box>

            </SimpleGrid>

    </Flex>
  );
}

const styles = StyleSheet.create({
    parent: {
        backgroundColor: '#d3d3d3',
        padding: '0px 0', // Reduced padding, added zero padding on the sides
        width: '100%',
        bottom: 0, // Aligns footer at the bottom of the viewport
        left: 0, // Ensures the footer spans the entire width of the viewport
    }
});

export default MobileFooter;