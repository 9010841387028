import Navbar from '../components/Navbar';
import Footer from 'components/Footer';
import { useSelector } from "react-redux";
import MobileNavbar from 'components/MobileNavbar';
import MobileFooter from 'components/MobileFooter';

import GradAlumni from 'components/NextconLabAlumni/GradAlumni';
import UndergradAlumni from 'components/NextconLabAlumni/UndergradAlumni';
import PostdocAlumni from 'components/NextconLabAlumni/PostdocAlumni';

function Alumni() {

  const isMobile = useSelector(state => state.windowSize.isMobile);

  return (
    <>
    {isMobile ? (
      <>
        <MobileNavbar />
        <GradAlumni />
        <hr />
        <PostdocAlumni />
        <hr />
        <UndergradAlumni />
        <MobileFooter />
      </>
    ) : (
      <>
        <Navbar />
        <GradAlumni />
        <hr />
        <PostdocAlumni />
        <hr />
        <UndergradAlumni />
        <Footer />
      </>
    )}
    </>
  );
}

export default Alumni;
