import React from 'react';
import { Flex, Link, Text, Box } from '@chakra-ui/react';

function Surveys() {
    return (
    <>
        <Flex direction="column" align="center" width="100%">
            <Text style={styles.TITLE}>Surveys and Tutorials Contributed By SCVIC & NEXTCON Lab</Text>
        </Flex>
    </>
    );
  }

const styles = {
    head: {
      maxWidth: '100vw',
      justifyContent: 'center',
      alignItems: 'center'
    },
    publicationTitle: {
      color: 'red',
      textDecoration: 'none'
    },
    TITLE: {
        color: 'blue',
        fontSize: '32px',
        alignItems: 'center',
        fontWeight: 'bolder',
        marginBottom: '10px',
    },
    navLink: {
        color: 'darkblue ',
        textDecorationLine: 'underline',
        fontWeight: 'bold',
        margin: '0 2px',
        fontSize: '20px',
    },
    publicationLink: {
      color: 'navy',
      textDecoration: 'none',
      marginRight: '10px'
    },
    sectionTitle: {
      fontSize: '25px',
      color: 'cornflowerblue ',
      marginBottom: '10px',
      fontWeight: 'bolder',
      textDecorationLine: 'underline',
      marginTop: '20px',
    },
    publicationInfo: {
      color: 'black'
    },
    authors: {
      color: 'black',
    },
    publish: {
        color: 'red',
        fontWeight: 'bold'
      },
    hover: {
      textDecoration: 'underline'
    },
    bulletList: {
      listStyleType: 'disc', // Bullets for list items
      paddingLeft: '40px', // Indent items to line up text nicely
  },
  };


  export default Surveys;