import Navbar from '../components/Navbar';
import Footer from 'components/Footer';
import { useSelector } from "react-redux";
import MobileNavbar from 'components/MobileNavbar';
import MobileFooter from 'components/MobileFooter';

import Contact from 'components/NextconLabContact/Contact';

function Fcontact() {

  const isMobile = useSelector(state => state.windowSize.isMobile);

  return (
    <>
    {isMobile ? (
      <>
        <MobileNavbar />
        <Contact/>
        <MobileFooter />
      </>
    ) : (
      <>
        <Navbar />
        <Contact/>
        <Footer />
      </>
    )}
    </>
  );
}

export default Fcontact;
