import {
  Flex, Box, SimpleGrid, Image,
} from "@chakra-ui/react";
import React from "react";
import { StyleSheet, css } from "aphrodite";
import { useSelector } from "react-redux";

function Nexpartners() {
    const isMobile = useSelector(state=> state.windowSize.isMobile);

    
    const ColNum = isMobile ? 2 : 4;
    return (
    <>
      <Box className={css(styles.head)} width='80%' marginLeft="10%" marginRight="10%">
          <Flex className={css(styles.container)}>
              NEXTCON Lab & SCVIC CENTRE COLLABORATORS 
          </Flex>
          <SimpleGrid columns={ColNum} spacing={5} textAlign="center" marginTop={5} marginBottom={5}>
              <Box justifyContent="center" alignContent="center">
                  <Flex justifyContent="center">
                      <Image src="col1.png" alt="" className={css(styles.images)} />
                  </Flex>
              </Box>
              <Box justifyContent="center" alignContent="center">
                  <Flex justifyContent="center">
                      <Image src="col2.png" alt="" className={css(styles.images)} />
                  </Flex>
              </Box>
              <Box justifyContent="center" alignContent="center">
                  <Flex justifyContent="center">
                      <Image src="col3.jpg" alt="" className={css(styles.images)} />
                  </Flex>
              </Box>
              <Box justifyContent="center" alignContent="center">
                  <Flex justifyContent="center">
                      <Image src="col4.png" alt="" className={css(styles.images)} />
                  </Flex>
              </Box>
              <Box justifyContent="center" alignContent="center">
                  <Flex justifyContent="center">
                      <Image src="col5.jpg" alt="" className={css(styles.images)} />
                  </Flex>
              </Box>
              <Box justifyContent="center" alignContent="center">
                  <Flex justifyContent="center">
                      <Image src="col6.jpg" alt="" className={css(styles.images)} />
                  </Flex>
              </Box>
              <Box justifyContent="center" alignContent="center">
                  <Flex justifyContent="center">
                      <Image src="col7.png" alt="" className={css(styles.images)} />
                  </Flex>
              </Box>
              <Box justifyContent="center" alignContent="center">
                  <Flex justifyContent="center">
                      <Image src="col8.png" alt="" className={css(styles.images)} />
                  </Flex>
              </Box>
              <Box justifyContent="center" alignContent="center">
                  <Flex justifyContent="center">
                      <Image src="col9.png" alt="" className={css(styles.images)} />
                  </Flex>
              </Box>
              <Box justifyContent="center" alignContent="center">
                  <Flex justifyContent="center">
                      <Image src="col10.png" alt="" className={css(styles.images)} />
                  </Flex>
              </Box>
              <Box justifyContent="center" alignContent="center">
                  <Flex justifyContent="center">
                      <Image src="col11.png" alt="" className={css(styles.images)} />
                  </Flex>
              </Box>
              <Box justifyContent="center" alignContent="center">
                  <Flex justifyContent="center">
                      <Image src="col12.jpg" alt="" className={css(styles.images)} />
                  </Flex>
              </Box>
              <Box justifyContent="center" alignContent="center">
                  <Flex justifyContent="center">
                      <Image src="col13.png" alt="" className={css(styles.images)} />
                  </Flex>
              </Box>
              <Box justifyContent="center" alignContent="center">
                  <Flex justifyContent="center">
                      <Image src="col14.jpg" alt="" className={css(styles.images)} />
                  </Flex>
              </Box>
              <Box justifyContent="center" alignContent="center">
                  <Flex justifyContent="center">
                      <Image src="col15.jpg" alt="" className={css(styles.images)} />
                  </Flex>
              </Box>
          </SimpleGrid>
      </Box>
    </>
    );
  }

  const styles = StyleSheet.create({
    head: {
        maxWidth: '100vw',
        marginTop: 0
    },
    parent: {
        maxWidth: '100vw',
        textAlign: 'center'
    },
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 30,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
        textAlign: 'center',
        textDecorationLine: 'underline'
    },
    textbox: {
        margin: '2%',
        marginTop: '0%',
        textAlign: 'center',
        fontSize: 18,
        justifyContent: 'center',
        alignContent: 'center'
    },
    names: {
        fontWeight: 'bold',
        fontSize: 20
    },
    title: {
        fontSize: 18,
    },
    images: {
        fit: "fit",
    },
    mobileImage: {
        width: "70%",
        borderRadius: "80%"
    }
})


  export default Nexpartners;