import Navbar from '../components/Navbar';
import Footer from 'components/Footer';
import { useSelector } from "react-redux";
import MobileNavbar from 'components/MobileNavbar';

import Media from 'components/NextconLabMedia/Media';
import MobileMedia from 'components/NextconLabMedia/MobileMedia';

import MobileFooter from 'components/MobileFooter';

function Fmedia() {

  const isMobile = useSelector(state => state.windowSize.isMobile);

  return (
    <>
    {isMobile ? (
      <>
        <MobileNavbar />
        <MobileMedia/>
        <MobileFooter />
      </>
    ) : (
      <>
        <Navbar />
        <Media/>
        <Footer />
      </>
    )}
    </>
  );
}

export default Fmedia;
