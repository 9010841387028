import React from 'react';
import { Flex, Link, Text, Box } from '@chakra-ui/react';
import { useSelector } from "react-redux";

function Surveys() {
    const isMobile = useSelector(state=> state.windowSize.isMobile);


    return (
    <>
        {isMobile ? 
        (<Flex direction="column" align="center" width="100%">
            <Text style={styles.TITLE}>Surveys and Tutorials Contributed By <br/> SCVIC & NEXTCON Lab</Text>
        </Flex>) : 
        (<Flex direction="column" align="center" width="100%">
            <Text style={styles.TITLE}>Surveys and Tutorials Contributed By SCVIC & NEXTCON Lab</Text>
        </Flex>)}
        

        {isMobile ? 
        (<>
          <Flex align="left" justify="left" wrap="nowrap" width="80%" marginLeft="0">
            <ul style={styles.bulletList2}> 
              <li><Link href="#adversarialML" style={styles.navLink}>[ADVERSARIAL MACHINE LEARNING]</Link> </li> 
              <li><Link href="#BTE" style={styles.navLink}>[BLOCKCHAIN TECHNOLOGIES]</Link> </li> 
              <li><Link href="#MC" style={styles.navLink}>[MOBILE CROWDSENSING]</Link>   </li> 
              <li><Link href="#DUC" style={styles.navLink}>[DRONE / UAV COMMUNICATIONS]</Link> </li> 
              <li><Link href="#FL" style={styles.navLink}>[FEDERATED LEARNING]</Link> </li> 
              <li><Link href="#CSE" style={styles.navLink}>[CYBERSECURITY]</Link> </li> 
              <li><Link href="#SC" style={styles.navLink}>[SMART CITIES]</Link>  </li> 
              <li><Link href="#DRO" style={styles.navLink}>[DISASTER RESILIENCE & OPTICAL ACCESS NETWORKS]</Link> </li> 
            </ul>  
          </Flex>
        </>
        ) : 
          (<>
          <Flex align="center" justify="center" wrap="nowrap" width="80%" marginLeft="160px">
              <Link href="#adversarialML" style={styles.navLink}>[ADVERSARIAL MACHINE LEARNING]</Link>
              <Link href="#BTE" style={styles.navLink}>[BLOCKCHAIN TECHNOLOGIES]</Link>
              <Link href="#MC" style={styles.navLink}>[MOBILE CROWDSENSING]</Link>  
          </Flex>
          <Flex align="center" justify="center" wrap="nowrap" width="80%" marginLeft="160px">
              <Link href="#DUC" style={styles.navLink}>[DRONE / UAV COMMUNICATIONS]</Link>
              <Link href="#FL" style={styles.navLink}>[FEDERATED LEARNING]</Link>
              <Link href="#CSE" style={styles.navLink}>[CYBERSECURITY]</Link>
              <Link href="#SC" style={styles.navLink}>[SMART CITIES]</Link>  
          </Flex>
          <Flex align="center" justify="center" wrap="nowrap" width="80%" marginLeft="160px">
              <Link href="#DRO" style={styles.navLink}>[DISASTER RESILIENCE & OPTICAL ACCESS NETWORKS]</Link>  
          </Flex>
        </>)}

        



        

        <Box style={styles.head}>
        <Flex direction="column" align="center" width="80%" marginLeft={"10%"}>
            <Text id="adversarialML" style={styles.sectionTitle}>ADVERSARIAL MACHINE LEARNING</Text>
            <Box mb="20px">
            <ul style={styles.bulletList}> <li>
            <Text  as="n" style={styles.authors}>Jinxin Liu, Michele Nogueira, Johan Fernandes and Burak Kantarci, "</Text>
            <Text  as="n" style={styles.publish}>Adversarial Machine Learning: A Multilayer Review of the State-of-the-Art and Challenges for Wireless and Mobile Systems,</Text>
            <Text  as="n" style={styles.publicationInfo}>" in IEEE Communications Surveys & Tutorials, vol. 24, no. 1, pp. 123-159, First Quarter 2022 </Text>
            <Link href="https://www.site.uottawa.ca/~bkantarc/BIBFILES/J85.html" style={styles.publicationLink} _hover={styles.hover} target="_blank">[Bibtex]</Link>
            <Link href="https://ieeexplore.ieee.org/document/9653662" style={styles.publicationLink} _hover={styles.hover} target="_blank">View in IEEEExplore</Link>
            </li> </ul> 
            </Box>
            <Box mb="20px">
            <ul style={styles.bulletList}> <li>
            <Text  as="n" style={styles.authors}>Comert, C., Gul, O. M., Kulhandjian, M., Touazi, A., Ellement, C., Kantarci, B., & D’Amours, C. (2022). "</Text>
            <Text  as="n" style={styles.publish}>Secure Design of Cyber-Physical Systems at the Radio Frequency Level: Machine and Deep Learning-Driven Approaches, Challenges and Opportunities.</Text>
            <Text  as="n" style={styles.publicationInfo}>" Artificial Intelligence for Cyber-Physical Systems Hardening, 123-154. </Text>
            <Link href="https://www.site.uottawa.ca/~bkantarc/BIBFILES/BC14.html" style={styles.publicationLink} _hover={styles.hover} target="_blank">[Bibtex]</Link>
            <Link href="https://link.springer.com/content/pdf/10.1007/978-3-031-16237-4_6.pdf?pdf=inline%20link" style={styles.publicationLink} _hover={styles.hover} target="_blank">View in IEEEExplore</Link>
            </li> </ul> 
            </Box>
            {/*More Content Here*/}
        </Flex>
        </Box>
        {/*More Header Here*/}
        <Box style={styles.head}>
        <Flex direction="column" align="center" width="80%" marginLeft={"10%"}>
            <Text id="BTE" style={styles.sectionTitle}>BLOCKCHAIN TECHNOLOGIES</Text>
            <Box mb="20px">
            <ul style={styles.bulletList}> <li>
            <Text  as="n" style={styles.authors}>Zhiyan Chen, Claudio Fiandrino, and Burak Kantarci. "</Text>
            <Text  as="n" style={styles.publish}>On blockchain integration into mobile crowdsensing via smart embedded devices: A comprehensive survey.</Text>
            <Text  as="n" style={styles.publicationInfo}>" Journal of Systems Architecture 115 (2021): 102011. </Text>
            <Link href="https://www.site.uottawa.ca/~bkantarc/BIBFILES/J76.html" style={styles.publicationLink} _hover={styles.hover} target="_blank">[Bibtex]</Link>
            <Link href="https://www.sciencedirect.com/science/article/abs/pii/S1383762121000229" style={styles.publicationLink} _hover={styles.hover} target="_blank">View in IEEEExplore</Link>
            </li> </ul> 
            </Box>
            {/*More Content Here*/}
        </Flex>
        </Box>
        <Box style={styles.head}>
        <Flex direction="column" align="center" width="80%" marginLeft={"10%"}>
            <Text id="MC" style={styles.sectionTitle}>MOBILE CROWDSENSING</Text>
            <Box mb="20px">
            <ul style={styles.bulletList}> <li>
            <Text  as="n" style={styles.authors}>A. Capponi, C. Fiandrino, B. Kantarci, L. Foschini, D. Kliazovich and P. Bouvry, "</Text>
            <Text  as="n" style={styles.publish}>A Survey on Mobile Crowdsensing Systems: Challenges, Solutions, and Opportunities,</Text>
            <Text  as="n" style={styles.publicationInfo}>" in IEEE Communications Surveys & Tutorials, vol. 21, no. 3, pp. 2419-2465, Third Quarter 2019 </Text>
            <Link href="https://www.site.uottawa.ca/~bkantarc/BIBFILES/J58.html" style={styles.publicationLink} _hover={styles.hover} target="_blank">[Bibtex]</Link>
            <Link href="https://ieeexplore.ieee.org/abstract/document/8703108" style={styles.publicationLink} _hover={styles.hover} target="_blank">View in IEEEExplore</Link>
            </li> </ul> 
            </Box>
            <Box mb="20px">
            <ul style={styles.bulletList}> <li>
            <Text  as="n" style={styles.authors}>Venkat Surya Dasari, Burak Kantarci, Maryam Pouryazdan, Luca Foschini, and Michele Girolami. "</Text>
            <Text  as="n" style={styles.publish}>Game theory in mobile crowdsensing: A comprehensive survey. </Text>
            <Text  as="n" style={styles.publicationInfo}>" Sensors 20, no. 7 (2020): 2055. </Text>
            <Link href="https://www.site.uottawa.ca/~bkantarc/BIBFILES/J65.html" style={styles.publicationLink} _hover={styles.hover} target="_blank">[Bibtex]</Link>
            <Link href="https://www.mdpi.com/1424-8220/20/7/2055/htm" style={styles.publicationLink} _hover={styles.hover} target="_blank">View in IEEEExplore</Link>
            </li> </ul> 
            </Box>
            <Box mb="20px">
            <ul style={styles.bulletList}> <li>
            <Text  as="n" style={styles.authors}>D. Belli, S. Chessa, B. Kantarci and L. Foschini,  "</Text>
            <Text  as="n" style={styles.publish}>Toward Fog-Based Mobile Crowdsensing Systems: State of the Art and Opportunities,</Text>
            <Text  as="n" style={styles.publicationInfo}>" in IEEE Communications Magazine, vol. 57, no. 12, pp. 78-83, December 2019  </Text>
            <Link href="https://www.site.uottawa.ca/~bkantarc/BIBFILES/J59.html" style={styles.publicationLink} _hover={styles.hover} target="_blank">[Bibtex]</Link>
            <Link href="https://ieeexplore.ieee.org/document/8930831" style={styles.publicationLink} _hover={styles.hover} target="_blank">View in IEEEExplore</Link>
            </li> </ul> 
            </Box>
            {/*More Content Here*/}
        </Flex>
        </Box>
        <Box style={styles.head}>
        <Flex direction="column" align="center" width="80%" marginLeft={"10%"}>
            <Text id="DUC" style={styles.sectionTitle}>DRONE / UAV COMMUNICATIONS</Text>
            <Box mb="20px">
            <ul style={styles.bulletList}> <li>
            <Text  as="n" style={styles.authors}>N. Parvaresh, M. Kulhandjian, H. Kulhandjian, C. D'Amours, B. Kantarci, "</Text>
            <Text  as="n" style={styles.publish}>A Tutorial on AI-Powered 3D Deployment of Drone Base Stations: State of the Art, Applications and Challenge,</Text>
            <Text  as="n" style={styles.publicationInfo}>" Elsevier Vehicular Communications, vol. 36, p. 100474, Aug. 2022.  </Text>
            <Link href="https://www.site.uottawa.ca/~bkantarc/BIBFILES/J87.html" style={styles.publicationLink} _hover={styles.hover} target="_blank">[Bibtex]</Link>
            <Link href="https://doi.org/10.1016/j.vehcom.2022.100474" style={styles.publicationLink} _hover={styles.hover} target="_blank">View in IEEEExplore</Link>
            </li> </ul> 
            </Box>
            {/*More Content Here*/}
        </Flex>
        </Box>
        <Box style={styles.head}>
        <Flex direction="column" align="center" width="80%" marginLeft={"10%"}>
            <Text id="FL" style={styles.sectionTitle}>FEDERATED LEARNING</Text>
            <Box mb="20px">
            <ul style={styles.bulletList}> <li>
            <Text  as="n" style={styles.authors}> Ji Chu Jiang, Burak Kantarci, Sema Oktug, and Tolga Soyata. "</Text>
            <Text  as="n" style={styles.publish}>Federated learning in smart city sensing: Challenges and opportunities.</Text>
            <Text  as="n" style={styles.publicationInfo}>" Sensors 20, no. 21 (2020): 6230 </Text>
            <Link href="https://www.site.uottawa.ca/~bkantarc/BIBFILES/J72.html" style={styles.publicationLink} _hover={styles.hover} target="_blank">[Bibtex]</Link>
            <Link href="https://www.mdpi.com/1424-8220/20/21/6230" style={styles.publicationLink} _hover={styles.hover} target="_blank">View in IEEEExplore</Link>
            </li> </ul> 
            </Box>
            {/*More Content Here*/}
        </Flex>
        </Box>
        <Box style={styles.head}>
        <Flex direction="column" align="center" width="80%" marginLeft={"10%"}>
            <Text id="CSE" style={styles.sectionTitle}>CYBERSECURITY</Text>
            <Box mb="20px">
            <ul style={styles.bulletList}> <li>
            <Text  as="n" style={styles.authors}> A. B. de Neira, B. Kantarci, M. Nogueira, "</Text>
            <Text  as="n" style={styles.publish}>Distributed denial of service attack prediction: Challenges, open issues and opportunities,</Text>
            <Text  as="n" style={styles.publicationInfo}>" Elsevier Computer Networks, volume 222, p. 1095532023, February 2023,</Text>
            <Link href="https://www.site.uottawa.ca/~bkantarc/BIBFILES/J95.html" style={styles.publicationLink} _hover={styles.hover} target="_blank">[Bibtex]</Link>
            <Link href="https://www.sciencedirect.com/science/article/abs/pii/S1389128622005874" style={styles.publicationLink} _hover={styles.hover} target="_blank">View in IEEEExplore</Link>
            </li> </ul> 
            </Box>
            <Box mb="20px">
            <ul style={styles.bulletList}> <li>
            <Text  as="n" style={styles.authors}> Jinxin Liu, Michele Nogueira, Johan Fernandes and Burak Kantarci, "</Text>
            <Text  as="n" style={styles.publish}>Adversarial Machine Learning: A Multilayer Review of the State-of-the-Art and Challenges for Wireless and Mobile Systems,</Text>
            <Text  as="n" style={styles.publicationInfo}>" in IEEE Communications Surveys & Tutorials, vol. 24, no. 1, pp. 123-159, First Quarter 2022 </Text>
            <Link href="https://www.site.uottawa.ca/~bkantarc/BIBFILES/J85.html" style={styles.publicationLink} _hover={styles.hover} target="_blank">[Bibtex]</Link>
            <Link href="https://ieeexplore.ieee.org/document/9653662" style={styles.publicationLink} _hover={styles.hover} target="_blank">View in IEEEExplore</Link>
            </li> </ul> 
            </Box>
            <Box mb="20px">
            <ul style={styles.bulletList}> <li>
            <Text  as="n" style={styles.authors}> Z. Chen, J. Liu, Y. Shen, M. Simsek, B. Kantarci, H. T. Mouftah, P. Djukic, "</Text>
            <Text  as="n" style={styles.publish}>Machine Learning-Enabled IoT Security: Open Issues and Challenges Under Advanced Persistent Threats,</Text>
            <Text  as="n" style={styles.publicationInfo}>" ACM Computing Surveys, vol. 55/5, pp. 1--37, 2022 </Text>
            <Link href="https://www.site.uottawa.ca/~bkantarc/BIBFILES/J86.html" style={styles.publicationLink} _hover={styles.hover} target="_blank">[Bibtex]</Link>
            <Link href="https://doi.org/10.1145/3530812" style={styles.publicationLink} _hover={styles.hover} target="_blank">View in IEEEExplore</Link>
            </li> </ul> 
            </Box>
            <Box mb="20px">
            <ul style={styles.bulletList}> <li>
            <Text  as="n" style={styles.authors}> Comert, C., Gul, O. M., Kulhandjian, M., Touazi, A., Ellement, C., Kantarci, B., & D'Amours, C. (2022). "</Text>
            <Text  as="n" style={styles.publish}>Secure Design of Cyber-Physical Systems at the Radio Frequency Level: Machine and Deep Learning-Driven Approaches, Challenges and Opportunities.</Text>
            <Text  as="n" style={styles.publicationInfo}>" Artificial Intelligence for Cyber-Physical Systems Hardening, 123-154. </Text>
            <Link href="https://www.site.uottawa.ca/~bkantarc/BIBFILES/BC14.html" style={styles.publicationLink} _hover={styles.hover} target="_blank">[Bibtex]</Link>
            <Link href="https://link.springer.com/content/pdf/10.1007/978-3-031-16237-4_6.pdf?pdf=inline%20link" style={styles.publicationLink} _hover={styles.hover} target="_blank">View in IEEEExplore</Link>
            </li> </ul> 
            </Box>
            <Box mb="20px">
            <ul style={styles.bulletList}> <li>
            <Text  as="n" style={styles.authors}> Habibzadeh, Hadi, Brian H. Nussbaum, Fazel Anjomshoa, Burak Kantarci, and Tolga Soyata.  "</Text>
            <Text  as="n" style={styles.publish}>A survey on cybersecurity, data privacy, and policy issues in cyber-physical system deployments in smart cities.</Text>
            <Text  as="n" style={styles.publicationInfo}>" ElsevierSustainable Cities and Society 50 (2019): 101660. </Text>
            <Link href="https://www.site.uottawa.ca/~bkantarc/BIBFILES/J57.html" style={styles.publicationLink} _hover={styles.hover} target="_blank">[Bibtex]</Link>
            <Link href="https://www.sciencedirect.com/science/article/pii/S2210670718316883?casa_token=A0t2F07BDTEAAAAA:MYjxb_PoJ5hN8rpxxTYoVC-KHhHr4SsamxFJytD7mxzyH0mi9yl-phP_2rUgbtH8dJc-es6hKPCg" style={styles.publicationLink} _hover={styles.hover} target="_blank">View in IEEEExplore</Link>
            </li> </ul> 
            </Box>
            <Box mb="20px">
            <ul style={styles.bulletList}> <li>
            <Text  as="n" style={styles.authors}> Quintal, Kyle, Burak Kantarci, Melike Erol-Kantarci, Andrew Malton, and Andrew Walenstein. "</Text>
            <Text  as="n" style={styles.publish}>Contextual, behavioral, and biometric signatures for continuous authentication.</Text>
            <Text  as="n" style={styles.publicationInfo}>" IEEE Internet Computing 23, no. 5 (2019): 18-28. </Text>
            <Link href="https://www.site.uottawa.ca/~bkantarc/BIBFILES/J62.html" style={styles.publicationLink} _hover={styles.hover} target="_blank">[Bibtex]</Link>
            <Link href="https://ieeexplore.ieee.org/abstract/document/8836081" style={styles.publicationLink} _hover={styles.hover} target="_blank">View in IEEEExplore</Link>
            </li> </ul> 
            </Box>
            {/*More Content Here*/}
        </Flex>
        </Box>
        <Box style={styles.head}>
        <Flex direction="column" align="center" width="80%" marginLeft={"10%"}>
            <Text id="SC" style={styles.sectionTitle}>SMART CITIES</Text>
            <Box mb="20px">
            <ul style={styles.bulletList}> <li>
            <Text  as="n" style={styles.authors}> Habibzadeh, Hadi, Cem Kaptan, Tolga Soyata, Burak Kantarci, and Azzedine Boukerche. "</Text>
            <Text  as="n" style={styles.publish}>Smart city system design: A comprehensive study of the application and data planes.</Text>
            <Text  as="n" style={styles.publicationInfo}>" ACM Computing Surveys (CSUR) 52, no. 2 (2019): 1-38.</Text>
            <Link href="https://www.site.uottawa.ca/~bkantarc/BIBFILES/J55.html" style={styles.publicationLink} _hover={styles.hover} target="_blank">[Bibtex]</Link>
            <Link href="https://dl.acm.org/doi/abs/10.1145/3309545" style={styles.publicationLink} _hover={styles.hover} target="_blank">View in IEEEExplore</Link>
            </li> </ul> 
            </Box>
            {/*More Content Here*/}
            <Box mb="20px">
            <ul style={styles.bulletList}> <li>
            <Text  as="n" style={styles.authors}> Habibzadeh, Hadi, Tolga Soyata, Burak Kantarci, Azzedine Boukerche, and Cem Kaptan. "</Text>
            <Text  as="n" style={styles.publish}>Sensing, communication and security planes: A new challenge for a smart city system design.</Text>
            <Text  as="n" style={styles.publicationInfo}>" Computer Networks 144 (2018): 163-200.</Text>
            <Link href="https://www.site.uottawa.ca/~bkantarc/BIBFILES/J51.html" style={styles.publicationLink} _hover={styles.hover} target="_blank">[Bibtex]</Link>
            <Link href="https://www.sciencedirect.com/science/article/pii/S1389128618306534?casa_token=c1M-9vFJkfAAAAAA:L4aSWaj_uj0iYbWREaWYngpeZBI_tTH9DwLA19j7Bq5Ts9vP0FJ3oUiOebdLOhxagftG8Vfvxnva" style={styles.publicationLink} _hover={styles.hover} target="_blank">View in IEEEExplore</Link>
            </li> </ul> 
            </Box>
            <Box mb="20px">
            <ul style={styles.bulletList}> <li>
            <Text  as="n" style={styles.authors}> Habibzadeh, Hadi, Andrew Boggio-Dandry, Zhou Qin, Tolga Soyata, Burak Kantarci, and Hussein T. Mouftah. "</Text>
            <Text  as="n" style={styles.publish}>Soft sensing in smart cities: Handling 3Vs using recommender systems, machine intelligence, and data analytics.</Text>
            <Text  as="n" style={styles.publicationInfo}>" IEEE Communications Magazine 56, no. 2 (2018): 78-86. </Text>
            <Link href="https://www.site.uottawa.ca/~bkantarc/BIBFILES/J46.html" style={styles.publicationLink} _hover={styles.hover} target="_blank">[Bibtex]</Link>
            <Link href="https://ieeexplore.ieee.org/abstract/document/8291118?casa_token=9mkJywoiXKUAAAAA:Qdi00oiTNNekr83S4Lyn7GS9SVXtIUGSiqh9ZEHxcZmZhplAV-30jtGtp9eFNm0FO9_EDPJho1Vs" style={styles.publicationLink} _hover={styles.hover} target="_blank">View in IEEEExplore</Link>
            </li> </ul> 
            </Box>
        </Flex>
        </Box>
        <Box style={styles.head}>
        <Flex direction="column" align="center" width="80%" marginLeft={"10%"}>
            {isMobile ? (<Text id="DRO" style={styles.sectionTitle}>DISASTER RESILIENCE & <br/> OPTICAL ACCESS NETWORKS</Text>) : 
            (<Text id="DRO" style={styles.sectionTitle}>DISASTER RESILIENCE & OPTICAL ACCESS NETWORKS</Text>)}


            <Box mb="20px">
            <ul style={styles.bulletList}> <li>
            <Text  as="n" style={styles.authors}> D. Cicek and B. Kantarci, "</Text>
            <Text  as="n" style={styles.publish}>Use of Mobile Crowdsensing in Disaster Management: A Systematic Review, Challenges, and Open Issues,</Text>
            <Text  as="n" style={styles.publicationInfo}>" Sensors, 23(3):1699, Jan 2023.</Text>
            <Link href="https://www.site.uottawa.ca/~bkantarc/BIBFILES/J96.html" style={styles.publicationLink} _hover={styles.hover} target="_blank">[Bibtex]</Link>
            <Link href="https://www.mdpi.com/1424-8220/23/3/1699" style={styles.publicationLink} _hover={styles.hover} target="_blank">View in IEEEExplore</Link>
            </li> </ul> 
            </Box>
            {/*More Content Here*/}
            <Box mb="20px">
            <ul style={styles.bulletList}> <li>
            <Text  as="n" style={styles.authors}> Rak, Jacek, Rita Girão-Silva, Teresa Gomes, Georgios Ellinas, Burak Kantarci, and Massimo Tornatore. "</Text>
            <Text  as="n" style={styles.publish}>Disaster resilience of optical networks: State of the art, challenges, and opportunities.</Text>
            <Text  as="n" style={styles.publicationInfo}>" Optical Switching and Networking 42 (2021): 100619. </Text>
            <Link href="https://www.site.uottawa.ca/~bkantarc/BIBFILES/J74.html" style={styles.publicationLink} _hover={styles.hover} target="_blank">[Bibtex]</Link>
            <Link href="https://www.sciencedirect.com/science/article/pii/S1573427721000163" style={styles.publicationLink} _hover={styles.hover} target="_blank">View in IEEEExplore</Link>
            </li> </ul> 
            </Box>
            <Box mb="20px">
            <ul style={styles.bulletList}> <li>
            <Text  as="n" style={styles.authors}> Kantarci, Burak, and Hussein T. Mouftah. "</Text>
            <Text  as="n" style={styles.publish}>Bandwidth distribution solutions for performance enhancement in long-reach passive optical networks.</Text>
            <Text  as="n" style={styles.publicationInfo}>" IEEE Communications Surveys & Tutorials 14, no. 3 (2011): 714-733.</Text>
            <Link href="https://www.site.uottawa.ca/~bkantarc/BIBFILES/J13.html" style={styles.publicationLink} _hover={styles.hover} target="_blank">[Bibtex]</Link>
            <Link href="https://ieeexplore.ieee.org/abstract/document/6007074" style={styles.publicationLink} _hover={styles.hover} target="_blank">View in IEEEExplore</Link>
            </li> </ul> 
            </Box>
        </Flex>
        </Box>
        
        <hr />
    </>
    );
  }

const styles = {
    head: {
      maxWidth: '100vw',
      justifyContent: 'center',
      alignItems: 'center'
    },
    publicationTitle: {
      color: 'red',
      textDecoration: 'none'
    },
    TITLE: {
        color: 'dodgerblue ',
        fontSize: '32px',
        alignItems: 'center',
        fontWeight: 'bolder',
        marginBottom: '10px',
    },
    navLink: {
        color: 'darkblue ',
        textDecorationLine: 'underline',
        fontWeight: 'bold',
        margin: '0 2px',
        fontSize: '20px',
    },
    publicationLink: {
      color: 'blue',
      textDecoration: 'underline',
      marginRight: '10px',
      fontWeight: 'bold',
    },
    sectionTitle: {
      fontSize: '25px',
      color: 'cornflowerblue ',
      marginBottom: '10px',
      fontWeight: 'bolder',
      textDecorationLine: 'underline',
      marginTop: '20px',
    },
    publicationInfo: {
      color: 'black'
    },
    authors: {
      color: 'black',
    },
    publish: {
        color: 'red',
        fontWeight: 'bold'
      },
    hover: {
      textDecoration: 'underline'
    },
    bulletList: {
      listStyleType: 'disc', // Bullets for list items
      paddingLeft: '40px', // Indent items to line up text nicely
    },
    bulletList2: {
      listStyleType: 'none', // Bullets for list items
      paddingLeft: '40px', // Indent items to line up text nicely
    },
  };


  export default Surveys;