import {Flex} from "@chakra-ui/react";
import React from 'react';
import './Researchers.css';


function Researchers() {
  return (
    <Flex margin="center" textAlign="left" alignItems= 'center' justifyContent= 'center'>
      <div className="researchers-container">
        <h1>Former Undergraduate Student Researchers</h1>
        
        <div className="researcher-info">
          <p><strong>Oliver Benning</strong>, (University of Ottawa, Computer Science Honours Project), June 2020</p>
          <p> <u>Current Position:</u> <i>Master of Computer Science student, University of Ottawa, ON</i></p>
        </div>
        <div className="researcher-info">
          <p><strong>Xuankai Chen</strong>, (University of Ottawa, Computer Science Honors Project, January 2020, 2nd Winner of Cognos Prize, Fall 2019)</p>
          <p> <u>Current Position:</u> <i>Software Engineer, Capital One, Toronto, ON</i></p>
        </div>
        <div>
          <p>
            <strong>Mark Traquair</strong>, (University of Ottawa, Computer Science Honors Project, June 2019, 
            <a style={{ color: 'blue' }} href="https://www.linkedin.com/posts/bkantarci_nextconlab-congratulations-research-activity-6537056234145660928-pwCj/" target="_blank" rel="noopener noreferrer">
              Winner of Cognos Prize winter 2019
            </a>)
          </p>
          <p>
            <u>Current Position:</u> <i>Machine Learning Developer, Summatti, Waterloo, ON</i>
          </p>
        </div>
        <div className="researcher-info">
          <p><strong>Asish Khiani</strong>, (University of Ottawa, Computer Science Honors Project, June 2019)</p>
          <p> <u>Current Position:</u> <i>Associate Software Engineer, Capital One, Toronto, ON</i></p>
        </div>
        <div className="researcher-info">
          <p><strong>Alek Hadzidedic</strong>, (University of Ottawa, Computer Science Honors Project, June 2019)</p>
          <p> <u>Current Position:</u> <i>-</i></p>
        </div>
        <div className="researcher-info">
          <p><strong>Nicholas Jordan</strong>, (University of Ottawa, Computer Science Honors Project, Dec. 2018)</p>
          <p> <u>Current Position:</u> <i>Data Forensic Analyst</i></p>
        </div>
        <div className="researcher-info">
          <p><strong>Nikita Pekin</strong>, (University of Ottawa, Computer Science Honors Project, Dec. 2018)</p>
          <p> <u>Current Position:</u> <i>Kandy Software Designer, Ribbon Communications</i></p>
        </div>
        <div className="researcher-info">
          <p><strong>Irem Ural</strong>, (Visiting student from Bilkent University- Department of Computer Engineering in Summer 2018)</p>
          <p> <u>Current Position:</u> <i>4-th year student in Computer Engineering major at Bilkent University, Turkey</i></p>
        </div>
        <div className="researcher-info">
          <p><strong>Kyle Quintal </strong>, (University of Ottawa, Capstone project in Software Engineering, May 2018)</p>
          <p> <u>Current Position:</u> <i>Master of Computer Science student, University of Ottawa (continuing Master at NEXTCON)</i></p>
        </div>
        <div className="researcher-info">
          <p><strong>Philip A. K. Lorimer </strong>, (University of Ottawa, Capstone project in Software Engineering, May 2018)</p>
          <p> <u>Current Position:</u> <i>Software Engineer, Communication Security Establishment Canada</i></p>
        </div>
        <div className="researcher-info">
          <p><strong>Alan Ross-Ross</strong>, (University of Ottawa, Capstone project in Software Engineering, May 2018)</p>
          <p> <u>Current Position:</u> <i>Software Engineer at Ribbon Communications, Ottawa, ON</i></p>
        </div>
        <div className="researcher-info">
          <p><strong>Shawn Lamothe</strong>, (University of Ottawa, Capstone project in Software Engineering, May 2018)</p>
          <p> <u>Current Position:</u> <i>Software Engineer, Electronic Arts-EA, (Vancouver, BC)</i></p>
        </div>
        <div className="researcher-info">
          <p><strong>Zachary Paquette</strong>, (University of Ottawa, Capstone project in Software Engineering, May 2018)</p>
          <p> <u>Current Position:</u> <i>-</i></p>
        </div>
        <div className="researcher-info">
          <p><strong>Jing Yi Dai </strong>, (University of Ottawa, Capstone project in Software Engineering, May 2018)</p>
          <p> <u>Current Position:</u> <i>-</i></p>
        </div>
        <div className="researcher-info">
          <p><strong>Michael Abourahal</strong>, (University of Ottawa, Computer Science Honors Project, May 2018)</p>
          <p> <u>Current Position:</u> <i>4-th year student at University of Ottawa</i></p>
        </div>
        <div className="researcher-info">
          <p><strong>Samer Akiki</strong>, (Clarkson-ECE Honors Student. Graduated in May 2017)</p>
          <p> <u>Current Position:</u> <i>Product Engineer at Globalfoundaries, San Diego, CA</i></p>
        </div>
        <div className="researcher-info">
          <p><strong>Alexander Cartier</strong>, (Clarkson-CompE undergraduate, Graduated in May 2016. Directed Study in Spring 2015, on Scalable Platforms for Internet of Things)</p>
          <p> <u>Current Position:</u> <i>-</i></p>
        </div>
        <div className="researcher-info">
          <p><strong>Connor D. Pearsall</strong>, (Clarkson-SoftE graduate, Cloud Systems and Networks in Spring 2015, co-author of a paper on Cloud and Smart cities)</p>
          <p> <u>Current Position:</u> <i>Collaborator</i></p>
        </div>
        <div className="researcher-info">
          <p><strong>David Lee</strong>, (Clarkson-CompE undergraduate, Graduated in 2016. Directed Study in Spring 2015, on Scalable Platforms for Internet of Things)</p>
          <p> <u>Current Position:</u> <i>Product Engineer at Globalfoundaries, San Diego, CA</i></p>
        </div>
        <div className="researcher-info">
          <p><strong>Kevin Carr </strong>, (Clarkson-SoftE graduate, Cloud Systems and Networks in Spring 2015, co-author of a paper on Cloud and Smart cities) </p>
          <p> <u>Current Position:</u> <i>Collaborator</i></p>
        </div>
        <div className="researcher-info">
          <p><strong>P. M. Glasser</strong>, (Clarkson-EE MSc graduate, Cloud Systems and Networks in Spring 2015 co-author of a paper on mobile social networks in IEEE GLOBECOM 2015)</p>
          <p> <u>Current Position:</u> <i>Collaborator</i></p>
        </div>
        
      </div>
    </Flex>
  );
}

/*<p><strong>Mark Traquair</strong> (University of Ottawa, Computer Science Honors Project, June 2019, <span className="award">Winner of Cognos Prize winter 2019</span>)</p>*/


export default Researchers;