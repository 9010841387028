import {Flex} from "@chakra-ui/react";
import React from 'react';
import './Researchers.css';


function Researchers() {
  return (
    <Flex margin="center" textAlign="left" alignItems= 'center' justifyContent= 'center'>
      <div className="researchers-container">
        <h1>Former Postdoctoral Researchers</h1>

        <div className="researcher-info">
          <p><strong>Omer Melih Gül </strong> (2023)</p>
          <p> <u>Current Position:</u> <i>Associate Professor in the Informatics Institute at Istanbul Technical University (ITU) |</i></p>
        </div>
        <div className="researcher-info">
          <p><strong>Tuerxun Wali </strong> (2022)</p>
          <p> <u>Current Position:</u> <i>Gnowi |</i></p>
        </div>
      </div>
    </Flex>
  );
}



export default Researchers;