import React from 'react';
import {Flex,
        Image,
        Drawer,
        DrawerBody,
        DrawerFooter,
        DrawerHeader,
        DrawerOverlay,
        DrawerContent,
        useDisclosure,
        Button,
        IconButton,
        Box,
        MenuButton,
        MenuItem,Menu,
        MenuList, Text, SimpleGrid
    } from '@chakra-ui/react';
import { StyleSheet, css } from 'aphrodite';
import { FaBars } from "react-icons/fa6";
import { Link } from "react-router-dom"; // Import useLocation and Link

function MobileNavbar() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
  return (
    <>
    <Box className={css(styles.container)}>
        <Flex >
            <Box height="5%">
                <Link to='/'>
                    <Image src="nextcon_logo.png" alt="nserc logo" boxSize="40%" fit="contain" />
                </Link>
            </Box>
            <Flex width="100%" alignItems='center'>
                <IconButton ref={btnRef} onClick={onOpen} size='lg' variant='ghost' icon={<FaBars />} className={css(styles.icon)}/>
            </Flex>
        </Flex>
        <Flex className={css(styles.slidingTab)}>
            <Flex className={css(styles.content)}>
            {/* className={css(styles.content)} */}
                <Text fontSize="16pt" fontFamily="'Rockwell Extra Bold', serif" color="darkgreen" as="i">
                    Smart Connected Vehicles Innovation Centre (SCVIC)
                </Text>
                <Text  as="i" fontSize="16pt" fontFamily="'Rockwell Extra Bold', serif" color="forestgreen">
                    &nbsp;&nbsp;&amp;
                </Text>
                <Text as="i" fontSize="16pt" fontFamily="'Rockwell Extra Bold', serif" color="forestgreen">
                    &nbsp;Next
                </Text>
                <Text as="i" fontSize="16pt" fontFamily="'Rockwell Extra Bold', serif" color="darkgreen">
                    &nbsp;Generation&nbsp;
                </Text>
                <Text as="i" fontSize="16pt" fontFamily="'Rockwell Extra Bold', serif" color="forestgreen">
                    Co
                </Text>
                <Text as="i" fontSize="16pt" fontFamily="'Rockwell Extra Bold', serif" color="darkgreen  ">
                    mmunications and Computing&nbsp;
                </Text>
                <Text as="i" fontSize="16pt" fontFamily="'Rockwell Extra Bold', serif" color="forestgreen">
                    N
                </Text>
                <Text as="i" fontSize="16pt" fontFamily="'Rockwell Extra Bold', serif" color="darkgreen  ">
                    etworks (
                </Text>
                <Text as="i" fontSize="16pt" fontFamily="'Rockwell Extra Bold', serif" color="forestgreen  ">
                    NEXTCON
                </Text>
                <Text as="i" fontSize="16pt" fontFamily="'Rockwell Extra Bold', serif" color="darkgreen  ">
                    ) Lab
                </Text>
                &emsp; &emsp; &emsp; &emsp;
                <Text fontSize="16pt" fontFamily="'Rockwell Extra Bold', serif" color="darkgreen" as="i">
                    Smart Connected Vehicles Innovation Centre (SCVIC)
                </Text>
                <Text  as="i" fontSize="16pt" fontFamily="'Rockwell Extra Bold', serif" color="forestgreen">
                    &nbsp;&nbsp;&amp;
                </Text>
                <Text as="i" fontSize="16pt" fontFamily="'Rockwell Extra Bold', serif" color="forestgreen">
                    &nbsp;Next
                </Text>
                <Text as="i" fontSize="16pt" fontFamily="'Rockwell Extra Bold', serif" color="darkgreen">
                    &nbsp;Generation&nbsp;
                </Text>
                <Text as="i" fontSize="16pt" fontFamily="'Rockwell Extra Bold', serif" color="forestgreen">
                    Co
                </Text>
                <Text as="i" fontSize="16pt" fontFamily="'Rockwell Extra Bold', serif" color="darkgreen  ">
                    mmunications and Computing&nbsp;
                </Text>
                <Text as="i" fontSize="16pt" fontFamily="'Rockwell Extra Bold', serif" color="forestgreen">
                    N
                </Text>
                <Text as="i" fontSize="16pt" fontFamily="'Rockwell Extra Bold', serif" color="darkgreen  ">
                    etworks (
                </Text>
                <Text as="i" fontSize="16pt" fontFamily="'Rockwell Extra Bold', serif" color="forestgreen  ">
                    NEXTCON
                </Text>
                <Text as="i" fontSize="16pt" fontFamily="'Rockwell Extra Bold', serif" color="darkgreen  ">
                    ) Lab
                </Text>

            </Flex>
        </Flex>
    </Box>

    <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
            <DrawerHeader>
                <Flex alignItems='center'>
                    <Flex width="100%" fontWeight={"bold"}>
                        Explore Nexconlab
                    </Flex>
                </Flex>
            </DrawerHeader>
          
          <DrawerBody width="100%">
            <Flex className={css(styles.DrawerContent)}>
                <Flex className={css(styles.links)}>
                    <Link to='/'>
                        Home
                    </Link>
                </Flex>
                <hr />
                <Flex className={css(styles.links)}>
                    <Menu className={css(styles.links)}>
                        <MenuButton as={Button}  variant='link' className={css(styles.links)}>
                            People
                        </MenuButton>
                        <MenuList>
                            <Link to='https://www.site.uottawa.ca/~bkantarc/' className={css(styles.links)} target="_blank">
                                <MenuItem>Lab Director</MenuItem>
                            </Link>
                            <Link to='/members' className={css(styles.links)}>
                                <MenuItem>Members</MenuItem>
                            </Link>
                            <Link to='/alumni' className={css(styles.links)}>
                                <MenuItem>Previous Members</MenuItem>
                            </Link>
                        </MenuList>
                    </Menu>
                </Flex>
                <hr />
                <Flex className={css(styles.links)}>
                    <Menu className={css(styles.links)}>
                        <MenuButton as={Button}  variant='link' className={css(styles.links)}>
                            Contributions
                        </MenuButton>
                        <MenuList>
                            <Link to='https://www.site.uottawa.ca/~bkantarc/publications.html' className={css(styles.links)} target="_blank">
                                <MenuItem>Publications</MenuItem>
                            </Link>
                            <Link to='/surveys-tutorials' className={css(styles.links)}>
                                <MenuItem>Surveys & Tutorials</MenuItem>
                            </Link>
                        </MenuList>
                    </Menu>
                </Flex>
                <hr />
                <Flex className={css(styles.links)}>
                    <Link to='https://ieee-dataport.org/search/node/Burak%20Kantarci'>
                        Datasets
                    </Link>
                </Flex>
                <hr />
                <Flex>
                <Menu className={css(styles.links)}>
                        <MenuButton as={Button}  variant='link' className={css(styles.links)}>
                        News
                        </MenuButton>
                        <MenuList>
                            <Link to='/news' className={css(styles.links)}>
                                <MenuItem>Announcements</MenuItem>
                            </Link>
                            <Link to='/media' className={css(styles.links)}>
                                <MenuItem>Media</MenuItem>
                            </Link>
                        </MenuList>
                    </Menu>
                </Flex>
                <hr />
                <Flex className={css(styles.links)}>
                    <Link to='/sponsors'>
                        Sponsors
                    </Link>
                </Flex>
                <hr />
                <Flex className={css(styles.links)}>
                    <Link to='/collaborators'>
                        Collaborators
                    </Link>
                </Flex>
                <hr />
            </Flex>
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

    <SimpleGrid columns={1} spacing={0} textAlign="center" marginTop={100}>
            <Flex justifyContent="center">
                <Image src="NEXTCON-image001.jpg" alt="NEXTCON-image001" width="60%" />
            </Flex>
            <Flex justifyContent="center">
                <Image src="NEXTCON-KANATA.jpg" alt="NEXTCON-KANATA" width="80%" />
            </Flex>
    </SimpleGrid>
    <Flex justifyContent="center" position="relative" overflowX="auto">
      <Text fontSize="20.5" fontFamily="'Times New Roman'" fontWeight="normal">
        <b>Director: Prof. Burak Kantarci,</b> School of Electrical Engineering and Computer Science, University of Ottawa
      </Text>
    </Flex>

    </>
  )
};

const styles = StyleSheet.create({
    container: {
        width: '100vw',
        backgroundColor: ' #d3d3d3  ' ,
        position: 'fixed',
        top: 0,
        zIndex: 10
    },
    icon: {
        justifyContent: 'right',
        width: '100%',
        padding: 0,
        marginRight: '5%',
        marginTop: 5,
        ':hover': {
            backgroundColor: '#e2e1dc'
        }
    },
    DrawerContent: {
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
    },
    links: {
        fontWeight: 'bold',
        marginBottom: 2,
        justifyContent: 'center',
        width: '100%',
    },
    Menulinks: {
        fontWeight: 'bold',
        marginBottom: 5,
        justifyContent: 'center',
        width: '100%',
    },
    slidingTab: {
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
      },
    content: {
        animationName: {
            '0%': {
                transform: 'translateX(100%)',
            },
            '100%': {
                transform: 'translateX(-350%)',
            },
        },
        animationDuration: '30s',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
        color: 'black', // Set text color to white or your preferred color
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        left: 0,
        width: '100%', // Ensure the content takes up the full width
    },
})

export default MobileNavbar