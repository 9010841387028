import React from 'react';
import { Flex, Box } from "@chakra-ui/react";
import { StyleSheet, css } from "aphrodite";

function Goal() {
  return (
        <Box className={css(styles.parent)}>
            <Flex className={css(styles.container)}>
                GOAL
            </Flex>
            <Box className={css(styles.textbox)}>
            Our primary goal is to push the boundaries of communications and computing technologies, emphasizing 6G 
            integration, AI, edge intelligence, and cybersecurity, to enable the next generation of smart connected 
            critical infrastructures. We prioritize developing secure, robust, and resilient networking solutions capable 
            of supporting ultra-low latency, high reliability, and massive connectivity requirements of future critical 
            infrastructure applications, leveraging the advancements of 6G networks while ensuring cybersecurity at every 
            stage. Additionally, we leverage the power of AI and edge intelligence to optimize vehicle-to-everything (V2X) 
            communication, intelligent traffic management, and enhanced driver assistance systems, paving the way for 
            safer, more efficient, and sustainable mobility solutions. Through strategic industrial collaborations, we 
            translate our research findings into real-world applications and innovations that benefit society, always 
            keeping cybersecurity at the forefront. Furthermore, we commit to disseminating our research outcomes through 
            high-quality publications in impactful journals and presentations at established conferences, ensuring our 
            contributions are recognized and adopted by the global research community and industry stakeholders.
            </Box>
        </Box> 
    )
}

const styles = StyleSheet.create({
    parent: {
        maxWidth: '100vw',
    },
    container: {
        maxWidth: '100%',
        justifyContent: 'center',
        fontSize: 24,
        margin: '1%',
        fontWeight: 'bolder',
        textShadow: '1px 1px gray',
    },
    textbox: {
        margin: '2%',
        marginTop: '0%',
        textAlign: 'center',
        fontSize: 18
    }
})

export default Goal