export const newsItems = [
        {
            date: '28 May 2024',
            text: 'During the fifth anniversary of the uOttawa-Kanata North campus, the Smart Connected Vehicles Innovation Centre welcomed guests and shared its vision and story.',
            link: 'https://www.youtube.com/watch?v=UZjw23oOAAk&feature=youtu.be'
        },
        {
            date: '15 Jan 2024',
            text: 'Dr. Kantarci has been selected for the IEEE Communications Software Technical Committee - 2023 Technical Achievement Award for contributions to "contributions to AI/ML-enabled communication network security and trustworthy sensing systems for the Internet of Things"',
            link: ''
        },
        {
            date: '01 Dec 2023',
            text: 'Our paper entitled "Generative Adversarial Networks to Secure Vehicle-to-Microgrid Services" has received the Best Student Paper Award in IEEE Virtual Conference on Communications (VCC 2023). Congratulations to PhD student Ahmed Omara! For news release',
            link: 'https://vcc2023.ieee-vcc.org/news/best-papers'
        },
        
        {
            date: '19 Sep 2023',
            text: 'Our comprehensive survey on Edge Learning for 6G-Enabled Internet of Things in Communications Surveys and Tutorials is now available online. Have a look.',
            link: 'https://ieeexplore.ieee.org/document/10255264'
        },
        
        {
            date: '29 May 2023',
            text: 'Our paper in colalboration with NETCORE Lab\'s Melike Erol-Kantarci and Pedro Ituria Rivera and NetExperience\'s Marcel Chenier and Berhard Herscovici ("RL meets Multi-Link Operation in IEEE 802.11be: Multi-Headed Recurrent Soft-Actor Critic-based Traffic Allocation") has received the Best Paper Award in IEEE International Conference on Communications (ICC 2023) NGNI symposium.',
            link: ''
        },
        
        {
            date: '18 Apr 2023',
            text: 'Dr. Kantarci has been awarded -as the lead PI, NSERC CREATE grant to lead the project Training and Research in Autonomous Vehicles for Reliable Services in the Air and on Land (TRAVERSAL). For news release',
            link: 'https://www.uottawa.ca/research-innovation/news-all/uottawa-push-boundaries-quantum-research-autonomous-vehicle-technology-nserc-grants'
        },
        
        {
            date: '14 Mar 2023',
            text: 'Our article entitled "Secure Industrial IoT Systems via RF Fingerprinting Under Impaired Channels With Interference and Noise" is now available in IEEExplore. Congratulations to the team! To view the article.',
            link: 'https://ieeexplore.ieee.org/document/10068504'
        },
        
        {
            date: '13 Mar 2023',
            text: 'Dr. Kantarci is listed among top Computer Scientists in Canada in 2022 (To view at Research.com.)',
            link: 'https://research.com/scientists-rankings/computer-science/ca?page=4'
        },
        
        {
            date: '06 Mar 2023',
            text: 'Our article entitled "A Continuous Actor-Critic Deep Q-Learning-Enabled Deployment of UAV Base Stations: Toward 6G Small Cells in the Skies of Smart Cities" is now available in IEEE O. Journal of the Communications Society. Congratulations Nahid!',
            link: 'https://ieeexplore.ieee.org/document/10059153'
        },
        
        {
            date: '01 Feb 2023',
            text: 'Our article entitled "Distributed denial of service attack prediction: Challenges, open issues and opportunities" is now available in Elsevier Computer Networks. Thank you Prof. Michele Nogueira for this fruitful collaboration,and congrats Anderson de Neira!',
            link: 'https://www.sciencedirect.com/science/article/abs/pii/S1389128622005874'
        },
        
        {
            date: '18 Nov 2022',
            text: 'Dr. Kantarci is leading two DND IDEaS projects to achieve stronger and more secure 5G-enabled infrastructures in collaboration with leading indsutry partners. $3M IDEaS DND funding advances 5G security research.',
            link: 'https://www2.uottawa.ca/about-us/media/news/driving-future-3m-ideas-dnd-funding-advances-5g-security-research-uottawa'
        },
        
        {
            date: '09 Nov 2022',
            text: 'We received the Best Paper Award in Wireless World Research Forum Meeting #48 with our work entitled "On the Impact of CDL and TDL Augmentation for RF Fingerprinting under Impaired Channels". Congratulations to all co-authors: O. M. Gul, M. Kulhandjian . B Kantarci, C. D\'Amours, A. Touazi, C. Ellement. To view the full story.',
            link: 'https://www.linkedin.com/posts/bkantarci_it-is-a-true-honour-to-receive-the-best-paper-activity-6996108588423274496-kir4?utm_source=share&utm_medium=member_desktop'
        },
        {
            date: '18 May 2022',
            text: 'Dataset of "A New Realistic Benchmark for Advanced Persistent Threats in Network Traffic, IEEE Networking Letters" is available for research use as long as the article is attributed properly and can be accessed from here ',
            link: 'https://ieee-dataport.org/documents/scvic-apt-2021'
        },
        {
            date: '18 May 2022',
            text: 'Our article (and a new public data set to use in cybersecurity research!) has been accepted to IEEE Networking Letters. J. Liu, Y. Shen, M. Simsek, B. Kantarci, H. Mouftah, M. Bagheri, P. Djukic, A New Realistic Benchmark for Advanced Persistent Threats in Network Traffic, IEEE Networking Letters, 2022. To view article',
            link: 'https://ieeexplore.ieee.org/document/9803189'
        },
        
        {
            date: '18 May 2022',
            text: 'Dr. Kantarci has been appointed as IEEE Systems Council Distinguished Lecturer for 2022-2024',
            link: ''
        },
        {
            date: '16 May 2022 ',
            text: 'Our article "A Tutorial on AI-Powered 3D Deployment of Drone Base Stations: State of the Art" has been accepted to Elsevier Vehicular Communications, Congrats Nahid, and thanks to all co-authors.  ',
            link: 'https://authors.elsevier.com/a/1f4w77tS-P~a9L '
        },
        {
            date: '07 Apr 2022 ',
            text: 'Our article "Machine Learning-Enabled IoT Security: Open Issues and Challenges Under Advanced Persistent Threats" has been accepted to ACM Computing Surveys. Congrats Zhiyan, Jinxin, Yu and thanks to all co-authors. ',
            link: 'https://dl.acm.org/doi/10.1145/3530812 '
        },
        
        {
            date: '03 Mar 2022',
            text: 'Our Smart Connected Innovation Centre has been featured in Canada\'s Innovation Leaders ',
            link: 'https://twitter.com/BurakKantarci/status/1501233494332252162/photo/3'
        },
        {
            date: '01 Mar 2022',
            text: 'Dr. Kantarci is listed among top Computer Scientists in Canada in 2021. To view at Research.com ',
            link: 'https://research.com/scientists-rankings/computer-science/ca?page=4'
        },
        {
            date: '21Jan2022',
            text: 'Dr. Kantarci received a Minister\'s Award of Excellence in the category of Innovation and Entrepreneurship from Ontario Ministry of Colleges and Universities.',
            link: 'https://www.linkedin.com/feed/update/urn:li:activity:6897276130748174336/'
        },

        

    ];