import Navbar from '../components/Navbar';
import Footer from 'components/Footer';
import { useSelector } from "react-redux";
import MobileNavbar from 'components/MobileNavbar';
import MobileFooter from 'components/MobileFooter';

import News from 'components/NextconLabNews/News';

function Fnews() {

  const isMobile = useSelector(state => state.windowSize.isMobile);

  return (
    <>
    {isMobile ? (
      <>
        <MobileNavbar />
        <News/>
        <MobileFooter />
      </>
    ) : (
      <>
        <Navbar />
        <News/>
        <Footer />
      </>
    )}
    </>
  );
}

export default Fnews;
