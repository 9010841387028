import React from 'react';
import { Flex, Text, Link} from '@chakra-ui/react';
import { useSelector } from "react-redux";

import { newsItems } from "./NewsList";

function NewsTable() {
    
    const isMobile = useSelector(state=> state.windowSize.isMobile);

    return (
      <>
        <Flex direction="column" align="center" width= '70%' marginLeft='15%' marginRight='15%'>
            <Text style={styles.title}>NEWS / ANNOUNCEMENTS</Text>
        </Flex>

        {isMobile ? (
      <>
        <table style={{ width: '90%', marginLeft:'5%',marginRight:'5%', marginBottom:'5px', border: '0px solid black', background:"gainsboro"}}>
            <tbody>
                {newsItems.map((item, index) => (
                    <tr key={index}>
                        <td style={{ padding: '8px', border: '0px solid black' }}>
                            <u><b> {item.date} </b></u>

                            <ul style={styles.bulletList}> <li>
                                {item.text} {item.link ?  (<Link href={item.link} style={{ color: 'blue' }} target="_blank"><u>Click to view.</u></Link>) : null}
                            </li> </ul> 
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
      </>
    ) : (
      <>
        <table style={{ width: '60%', marginLeft:'20%',marginRight:'20%', marginBottom:'5px', border: '0px solid black', background:"gainsboro"}}>
            <tbody>
                {newsItems.map((item, index) => (
                    <tr key={index}>
                        <td style={{ padding: '8px', border: '0px solid black' }}>
                            <u><b> {item.date} </b></u>

                            <ul style={styles.bulletList}> <li>
                                {item.text} {item.link ?  (<Link href={item.link} style={{ color: 'blue' }} target="_blank"><u>Click to view.</u></Link>) : null}
                            </li> </ul> 
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
      </>
    )}




      </>
    );
}

const styles = {
  title: {
      fontSize: '32px',
      alignItems: 'center',
      fontWeight: 'bolder',
      marginBottom: '10px',
  },
  NewsLink: {
    color: 'darkblue ',
    textDecorationLine: 'underline',
    fontWeight: 'bold',
    margin: '0 2px',
    fontSize: '20px',
    },
    bulletList: {
        listStyleType: 'square', // Bullets for list items
        paddingLeft: '20px', // Indent items to line up text nicely
      },
};

export default NewsTable;