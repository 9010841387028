import Navbar from '../components/Navbar';
import Footer from 'components/Footer';
import { useSelector } from "react-redux";
import MobileNavbar from 'components/MobileNavbar';
import MobileFooter from 'components/MobileFooter';

import Members from 'components/NextconLabMembers/Members';

function Home() {

  const isMobile = useSelector(state => state.windowSize.isMobile);

  return (
    <>
    {isMobile ? (
      <>
        <MobileNavbar />
        <Members />
        <MobileFooter />
      </>
    ) : (
      <>
        <Navbar />
        <Members />
        <Footer />
      </>
    )}
    </>
  );
}

export default Home;
